.theModal{
    top: 10px;
    width: 70vw!important;
    transition: 0.5s;
  @media screen and (max-width: 576px) {
    top: 0;
    width: 95vw!important;
  }
  .ModalIcon {
      svg {
        transition: 0.5s;
        width: 4em;
        height: 4em;
        color: whitesmoke;
        opacity: 0.3;
        &:hover {
          transition: 1s;
          opacity: 0.8;
        }
    }
  }
  .SwiftLeft {
    position: absolute;
    margin: 10px;
    left: 25px;
  }

  .SwiftRight {
    position: absolute;
    margin: 10px;
    right: 25px;
  }
}
