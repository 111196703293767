.App {
  padding-top: 60px;
  text-align: center;
  overflow-y: hidden;
  #footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index : 998;
  }
  .ant-back-top {
    z-index: 999;
  }
}