.AlbumCard{
  margin-left : auto;
  margin-right : auto;
  min-height: 300px;
  height: 100%;
}
figure{
  width:300px; /*container-width*/
  overflow:hidden; /*hide bounds of image */
  margin:0;   /*reset margin of figure tag*/
}
figure img{
  display:block; /*remove inline-block spaces*/
  width:100%; /*make image streatch*/
}

.ant-card-body{
  background: white;
  position: absolute;
  bottom: 0;
  width: 100%;
}
