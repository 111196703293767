#Dashboard {
  padding-top: 30px;
  min-height: 100vh;
  .tree-transfer .ant-transfer-list:first-child {
    min-width: 30vw;
    width: 50%;
    height: 50vh;
  }
  .tree-transfer .ant-transfer-list {
    min-width: 30vw;
    width: 40%;
    height: 50vh;
  }
  .LazyLoad {
    overflow: hidden;
  }
}
