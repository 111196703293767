#Home {
  min-height: 100vh;
  padding: 25px;
  @media screen and (max-width: 576px) {
    padding: 5px;
  }
  .timeBar {
    transition: 0.5s;
    position: fixed;
    float: left;
    height: 65vh;
    margin-left: 10px;
    margin-top : 60px;
    @media screen and (max-width: 576px) {
      margin-left: 0;
      margin-top : 20px;
    }
    .timeSwitch{
      margin-bottom: 15px;
    }
  }
  .react-photo-gallery--gallery {
    padding-left: 100px;
    transition: 0.5s;
    @media screen and (max-width: 576px) {
     padding-left: 70px;
    }
  }
}
.openModal{
  height: 32px;
  width: 32px;
  position: absolute;
  left: 50%;
  margin-left: -16px;
  top: 50%;
  margin-top: -16px;
  z-index: 999;
  display: none;
}

.lazyPhoto {
  &:hover {
    .openModal {
      display: block;
    }
    img {
      cursor: pointer;
      -webkit-filter: blur(2px);
      -moz-filter: blur(2px);
      -o-filter: blur(2px);
      -ms-filter: blur(2px);
      filter: blur(2px);
      background-color: #ccc;
    }

  }
}

