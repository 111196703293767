#Header {
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;
  #logo{
    &:hover {
      cursor: auto;
    }
  }
  .AetaLogo {
    position: fixed;
    width: 100px;
    left : 10px;
    transition: 0.5s;
    @media screen and (max-width: 576px) {
        position: relative;
      width: 50px;
    }
  }
  .AetaTitle {
    float: right;
    padding-left: 100px;
    margin: 0;
    @media screen and (max-width: 576px) {
      display: none;
    }
  }
}
.ant-drawer-content-wrapper{
  width: 350px!important;
}
